<template>
  <div>
    <section id="page-header">
      <div class="page-header-image parallax-bg-3 bg-image"
        style="background-image: url(static/image/page-header-bg-4.jpg);">
        <div class="cover bg-transparent-5-dark"></div>
      </div>
      <div class="page-header-inner tt-wrap">
        <div class="page-header-caption ph-caption-lg parallax-4 fade-out-scroll-3">
          <h1 class="page-header-title">艺术与我</h1>
          <hr class="hr-short">
          <div class="page-header-description">
            <p>艺术与我</p>
          </div>
        </div>
      </div>
    </section>
    <div v-for="(item, index) in themelist" :key="index">
      <section id="gallery-list-section" class="gl-categories">
        <div class="isotope-wrap tt-wrap">
          <div class="isotope col-3 gutter-3">
            <div class="isotope-items-wrap gli-colored gli-alter-5">
              <div class="grid-sizer"></div>
              <div class="isotope-item outdoor iso-height-1">

                <div class="gallery-list-item">
                  <div class="gl-item-image-wrap">
                    <a :href="'theme?id=' + item.id" class="gl-item-image-inner">
                      <div class="gl-item-image bg-image"
                        :style="'background-image: url(http://admin.artandme.cn/' + item.smallImage + '); background-position: 50% 50%'">
                      </div>

                      <span class="gl-item-image-zoom"></span>
                    </a>
                  </div>
                  <div class="gl-item-info">
                    <div class="gl-item-caption">
                      <h2 class="gl-item-title"><a :href="'theme?id=' + item.id">{{ item.name }}</a></h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    // HelloWorld
  },
  data() {
    return {
      themelist: []
    }

  },
  created() { this.GetTheme() },
  mounted() {

    let arr = [
      'static/js/bootstrap.min.js',
      'static/js/animsition.min.js',
      'static/js/jquery.easing.min.js',
      'static/js/isotope.pkgd.min.js',
      'static/js/imagesloaded.pkgd.min.js',
      'static/js/owl.carousel.min.js',
      'static/js/jquery.mousewheel.min.js',
      'static/js/jquery.mb.YTPlayer.min.js',
      'static/js/lightgallery-all.min.js',
      'static/js/theme.js',
      'static/js/demo-panel.js',
      'static/js/styleswitch.js'];
    arr.map((item) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = item;
      document.getElementsByTagName('body')[0].appendChild(script);
    });


  },
  methods: {
    GetTheme() {
      this.$httphelper.get('OpusApi/GetThemeList').then(res => {
        if (res.code == 200) {
          this.themelist = res.data;

        }

      });
    }

  }
}
</script>
<style scoped>
.gl-item-title{ font-size: 22px} 
@media screen and (max-width: 400px) { 
.gl-item-title {font-size: 14px} 
} 
</style>
