<template>
  <div>
    <headerVue></headerVue>
    <div id="body-content">
      <router-view />

    </div>
    <footerVue></footerVue>
  </div>

</template>

<script>
import headerVue from "./components/header.vue";
import footerVue from "./components/footer.vue";
export default {
  components: {
    headerVue,
    footerVue
  },
  mounted() {
    // 禁用右键
    document.oncontextmenu = function () {
      return false;
    };
    // 创建cnzz统计js
        const script = document.createElement('script');
        script.src = 'http://v1.cnzz.com/z_stat.php?id=1281101299&web_id=1281101299';
        script.type = 'text/javascript';
        document.body.appendChild(script);
  }
}
</script>

