<template>
    <section id="footer" class="footer-dark no-margin-top">
        <div class="footer-inner">
            <div class="footer-container tt-wrap">
                <div class="row">
                    <div class="col-md-3">

                        <!-- Begin footer logo -->
                        <div id="footer-logo">
                            <a href="index.html" class="logo-dark"><img src="static/picture/logo-dark.png"
                                    alt="logo"></a>
                            <a href="index.html" class="logo-light"><img src="static/picture/logo-light_art.png"
                                    alt="logo"></a>

                            <!-- for small screens -->
                            <a href="index.html" class="logo-dark-m"><img src="static/picture/logo-dark-m.png"
                                    alt="logo"></a>
                            <a href="index.html" class="logo-light-m"><img src="static/picture/logo-light_art.png"
                                    alt="logo"></a>
                        </div>
                        <!-- End footer logo -->

                    </div> <!-- /.col -->

                    <div class="col-md-5">

                        <!-- Begin footer text -->
                        <div class="footer-text">
                            <h4>- Art And Me</h4>
                            艺术与我
                        </div>
                        <!-- End footer text -->

                    </div> <!-- /.col -->



                </div> <!-- /.row -->
            </div>
            <!-- /.footer-container -->

            <div class="footer-bottom">
                <div class="footer-container tt-wrap">
                    <div class="row">
                        <div class="col-md-6 col-md-push-6">

                            <!-- Begin footer menu -->
                            <ul class="footer-menu">
                                <li><a href="/">Home</a></li>

                            </ul>
                            <!-- End footer menu -->

                        </div> <!-- /.col -->

                        <div class="col-md-6 col-md-pull-6">

                            <!-- Begin footer copyright -->
                            <div class="footer-copyright">
                                <p><img src="static/image/beian.png" width="20px">
                                    <span style="padding-left:10px;padding-top:10px"><a style="color:#fff;"
                                            target="_blank"
                                            href="https://beian.miit.gov.cn/">冀ICP备2021024271号-1</a></span>
                                    <span style="padding-left:10px;">
                                        <img data-v-decb7426=""
                                            src="data:image/gif;base64,R0lGODlhMgAMAMQAAAAAAGaZADOZzP8zM8zMzNLS0tbW1tnZ2d3d3eHh4eTk5Ojo6Ozs7PDw8PX19fr6+v39/QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH/C05FVFNDQVBFMi4wAwEAAAAh/g9Qb3dlcmVkIGJ5IEFGRUkAIfkECQ8AEQAsAAAAADIADAAABaggIY5kaZ5oakJs675wLM+t+Nx4ru/84P/AYFDkKAKOAEdSqUQ2j8WoQ0ilEo2ILAIKQDiy3e/WKy2bz0VRYw04rBvto/vYmAPq77xgz+/7+SIMggAGggyEhwYADAaFi42GkQx/lH4iC5hIAAsABZwFmpxImKSlpqenIgqrCiStqwStI7KqrLasAbm6u7siCb/AwcLDxMO8x7oiWsvMzc7P0M0q09TVJSEAIfkECQ8AEQAsAAAAADIADAAABaogIY5kaZ5oakJs675wLM+t+Nx4ru98/wzAoHAgchgBSIBDuVwmnUijdEp1DK/FI2KLiAIQju033AVXz2ipqMEGHNgNN/KNbNABdrieLej7/4ACIgyEAAaEDIaJBgAMBoeNj4iTiIGWfiILmkkACwAFngWcnkmapqeoqakiCq0KJK+tBK8jtKyuuK4Bu7y9vSIJwcLDxMXGxb7JvCJczc7P0NHSzyrV1tclIQAh+QQJDwARACwAAAAAMgAMAAAFqiAhjmRpnmhqQmzrvnAsz6343Hiu73z/DMCgcCByGAFIgEO5XCadSKN0SnUMr8UjYouIAhCO7TfcBVfPaKmowQYc2A038o1s0AF2uJ4t6Pv/gAIiDIQABoQMhokGAAwGh42PiJOIgZZ+IguaSQALAAWeBZyeSZqmp6ipqSIKrQokr60EryO0rK6uAbq7vL27IgnBwsPExcbBvsm9IlzNzs/Q0dLPKtXW1yUhADs=">
                                    </span>
                                </p>
                            </div>
                            <!-- End footer copyright -->

                        </div>
                    </div> <!-- /.row -->
                </div> <!-- /.footer-container -->
            </div> <!-- /.footer-bottom -->

        </div> <!-- /.footer-inner -->

        <!-- Scroll to top button -->
        <a href="#body" class="scrolltotop sm-scroll" title="Scroll to top"><i class="fas fa-chevron-up"></i></a>

    </section>

</template>
