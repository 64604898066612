import { createApp } from "vue";

import axios from "axios";

import md5 from "js-md5";

let publicKey = "bosihuiwen@!bj";
axios.defaults.withCredentials = true;
// axios.defaults.baseURL = "http://192.168.0.111/api/";
axios.defaults.baseURL = "http://api.artandme.cn/api/";
export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params: params,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  ValidSignStr(dictionary) {
    let dictionary1 = JSON.parse(JSON.stringify(dictionary));
    for (var key in dictionary1) {
      dictionary1["'" + key.toLowerCase() + "'"] = dictionary1[key];
      delete dictionary1[key];
    }
    var sortKeys = Object.keys(dictionary1).sort();
    var str = "";
    for (var item in sortKeys) {
      var bl = false;
      var _Keyvalue = dictionary1[sortKeys[item]];
      if (typeof dictionary1[sortKeys[item]] != "number") {
        if (_Keyvalue instanceof Object == true) {
          bl = false;
        } else if (typeof dictionary1[sortKeys[item]] == "boolean") {
          bl = true;
        } else {
          bl = dictionary1[sortKeys[item]].length > 0 ? true : false;
        }
      } else {
        bl = true;
      }
      if (bl) {
        str += sortKeys[item] + "=" + dictionary1[sortKeys[item]] + "&";
        str = str.replace(/'/g, "");
      }
    }
    if (str != "") {
      str = str.substring(0, str.length - 1);
    }
    var Sign = md5(str.toLowerCase());
    return Sign;
  },

  post(url, data) {
    let PostData = data;
    delete PostData.Sign;
    createApp.set(PostData, "AppKey", publicKey);
    let Sign = this.ValidSignStr(PostData);
    createApp.set(PostData, "Sign", Sign);
    delete PostData.AppKey;
    return new Promise((resolve, reject) => {
      axios.post(url, PostData).then(
        (response) => {
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  },
};
