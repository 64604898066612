<template>
     <header id="header" class="header-show-hide-on-scroll menu-align-right">
      <div class="header-inner tt-wrap">
          <div id="logo">
              <a href="/" class="logo-dark"><img src="static/picture/logo-dark.png" alt="logo"></a>
              <a href="/" class="logo-light"><img src="static/picture/logo-light_art.png" alt="logo"></a>

              <!-- for small screens -->
              <a href="/" class="logo-dark-m"><img src="static/picture/logo-dark-m.png" alt="logo"></a>
              <a href="/" class="logo-light-m"><img src="static/picture/logo-light_art.png" alt="logo"></a>
          </div>
          <nav class="tt-main-menu">

              <div id="tt-m-menu-toggle-btn">
                  <span></span>
              </div>
              <div class="tt-menu-tools">
                  <!-- <ul>
                      <li>
                          <a href="#" class="tt-clobal-search-trigger"><i class="fas fa-search"></i></a>
                          <div class="tt-clobal-search">
                              <div class="tt-clobal-search-inner">
                                  <span class="tt-clobal-search-title">Search</span>
                                  <form id="tt-clobal-search-form" class="form-btn-inside" method="get"
                                      action="search-results.html">
                                      <input type="text" id="tt-clobal-search-input" name="search"
                                          placeholder="Type your keywords ...">
                                      <button type="submit"><i class="fas fa-search"></i></button>
                                  </form>
                              </div>
                              <div class="tt-clobal-search-close"><i class="tt-close-btn tt-close-light"></i></div>
                          </div> 
                      </li>
                  </ul> -->
              </div>

              <div class="tt-menu-collapse tt-submenu-dark">
                  <ul class="tt-menu-nav">
                      <li class="tt-submenu-wrap tt-submenu-master" >
                          <a href="/">首页</a>
                         
                      </li>
                     
                  </ul> 
              </div>

          </nav>
      </div>
  </header>
</template>
